import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import Columns from "../components/columns.js";
import Divider from "../components/divider.js";
import Layout from "../components/layout.js";
import Post from "../components/post.js";

import { headshot, resume } from "../assets";
import { camera, email, instagram, paper, twitter } from "../images/icons";
import {
  anima1,
  anima2,
  anima3,
  anima4,
  anima5,
} from "../images/theater/anima";
import {
  camping1,
  camping2,
  camping3,
  camping4,
} from "../images/theater/camping";
import { cpp1, cpp2, cpp3, cpp4 } from "../images/theater/cpp";
import diner1 from "../images/theater/diner/diner1.jpg";
import { dogs1, dogs2, dogs3, dogs4, dogs5 } from "../images/theater/dogs";
import fall1 from "../images/theater/fall/fall1.jpg";
import { ll1, ll2, ll3, ll4 } from "../images/theater/leatherandlace";
import {
  starman1,
  starman2,
  starman3,
  starman4,
  starman5,
} from "../images/theater/starman";
import { wilde1, wilde2, wilde3, wilde4 } from "../images/theater/wildeatheart";

const TheaterPage = () => {
  return (
    <Layout
      title="lars montanaro | theater"
      image={
        <StaticImage
          alt="Lars Montanaro, in ANIMAMUNDI"
          src="../images/header_theater.jpg"
        />
      }
    >
      <Columns>
        {/* LEFT COLUMN */}
        <div className="max-w-prose">
          <h2>BIO</h2>
          <p>
            lars montanaro (he/him) is a multi-disciplinary artist from the
            California mountains. he is a founding member of Chicken Big, an
            experimental comedy troupe (UCB, iO, Annoyance, Asylum NYC, Brooklyn
            Comedy Collective), and worked as the director of The Poetry Brothel
            NYC, an immersive poet-theatrical experience. his work has been
            featured at La MaMa ETC, PhysFest NYC, SF Sketchfest, Chicago
            iOFest, Oberlin College, The New School & NYU, and he has recently
            performed and developed projects with HERE Arts Center, En Garde
            Arts & The Center at West Park. he is trained in Meisner from the
            Terry Knickerbocker Studio and is currently pursuing an MFA in
            Contemporary Theater & Performance at The New School.
          </p>
          <h2 className="mt-6">NEWS</h2>
          <Post
            title="Contemporary Performance Practice - WaxFactory"
            images={[
              { original: cpp1 },
              { original: cpp2 },
              { original: cpp3 },
              { original: cpp4 },
            ]}
          >
            <p className="text-xs">
              two week study in Dubrovnik, Croatia. produced by WaxFactory, in
              collaboration with Clauda de Serpa Soures of Sasha Waltz and
              Guests, and Ellen Lauren of SITI Company. workshopping
              Dancetheater composition, Suzuki, and Viewpoints.
            </p>
          </Post>
          <Divider />
          <Post
            title="CAMPING - CND"
            images={[
              { original: camping1 },
              { original: camping2 },
              { original: camping3 },
            ]}
          >
            <p className="text-xs">
              two week study in Paris, France. organized by the Centre National
              de la Danse in Paris. took workshops with Euripides Laskaridis,
              Nach, and many other dance professionals. taught a workshop on
              interdisciplinary dance composition.
            </p>
          </Post>
          <Divider />
          <Post
            title="Leather & Lace - Poetry Brothel NYC"
            images={[
              { original: ll1 },
              { original: ll2 },
              { original: ll3 },
              { original: ll4 },
            ]}
          >
            <p className="text-xs">
              an immersive poet-theatrical experience, directed by Lars
              Montanaro. featured performers include Lars Montanaro, Allison
              Boenig, Cassandra Rosebeetle, Mr. Words, J Rose, and more. musical
              acts by Blacknificent & Hot Club of Flatbush. photos by Shoshana
              Ehrenkanz.
            </p>
          </Post>
          <Divider />
          <Post
            title="STARMAN* - Center at West Park"
            images={[
              { original: starman1 },
              { original: starman2 },
              { original: starman3 },
              { original: starman4 },
              { original: starman5 },
            ]}
          >
            <p className="text-xs">
              created by Aubrey Clinedinst, starring Lars Montanaro, Elyse
              Brown, Phoenix Leigh, Matt Mancuso, and Rachel Weekley. original
              music by Matt Dunphy. photos by Richard Termine
            </p>
          </Post>
          <Divider />
          <Post
            title="Wilde at Heart - Poetry Brothel NYC"
            images={[
              { original: wilde1 },
              { original: wilde2 },
              { original: wilde3 },
              { original: wilde4 },
            ]}
          >
            <p className="text-xs">
              directed by Lars Montanaro. featured performers include Lars
              Montanaro, Allison Boenig, Cierra Martin, Venatrix, Cassandra
              Rosebeetle, and more. musical acts by Blacknificent & Hot Club of
              Flatbush. photos by Jenna Gardner.
            </p>
          </Post>
          <Divider />
          <Post
            title="Fall from Heaven - Poetry Brothel, Boston"
            images={[{ original: fall1 }]}
          >
            <p className="text-xs">
              an immersive poetry cabaret, starring Lars Montanaro, Shari
              Caplan, Sarina Nunez, Phoebe Flows, N.O. Moore, and more. musical
              acts by 3rian and the Familiars
            </p>
          </Post>
          <Divider />
          <Post
            title="Diner on the Edge - IRTE"
            images={[{ original: diner1 }]}
          >
            <p className="text-xs">
              an improvised play directed by William Berg. starring Lars
              Montanaro, Natalie Hunter, Nannette Deasy, Jamie Maloney,
              Glorianna Cody, Sam Katz, Esther Lu, and Robert Baumgardner.
              original music by Tym Moss.
            </p>
          </Post>
          <Divider />
          <Post title="Eternal Lines - Hudson Shakespeare Company">
            <p className="text-xs">
              written by Noelle Fair. starring Lars Montanaro, Emily Glaser, Ido
              Alongal, Charlotte Cassidy, Dylan Miller, Liz Claflin, and Tristan
              Goldman. *canceled due to COVID
            </p>
          </Post>
          <Divider />
          <Post
            title="ANIMAMUNDI"
            images={[
              { original: anima1 },
              { original: anima2 },
              { original: anima3 },
              { original: anima4 },
              { original: anima5 },
            ]}
          >
            <p className="text-xs">
              NYU Experimental Theatre Wing. created by Oliver Demers, in
              devised collaboration with Lars Montanaro, Bre Tomey, Meghan Piper
              Johnson, Lucie Allouche, and Maya Hendricks. original music by
              Oliver Demers, JD Askin, Max Chidzero, Yen-Yu Chen, and Ryo
              Jaeger. photos by Cati Kalinoski
            </p>
          </Post>
          <Divider />
          <Post
            title="Dogs Chase Shadows"
            images={[
              { original: dogs1 },
              { original: dogs2 },
              { original: dogs3 },
              { original: dogs4 },
              { original: dogs5 },
            ]}
          >
            <p className="text-xs">
              NYU Experimental Theatre Wing. created by Malena Pennycook,
              starring Lars Montanaro, Malena Pennycook, Chloe Troast, Oliver
              Demers, Abby Awe, and Laura Nelson. original music by Malena
              Pennycook
            </p>
          </Post>
        </div>
        {/* RIGHT COLUMN */}
        <div className="sm:ml-4">
          <div className="sm:sticky sm:top-14">
            <h2 className="mb-4">HEADSHOT + RESUME</h2>
            <div className="flex justify-around mx-4 my-2 max-w-[180px]">
              <a href={headshot} download>
                <img className="h-[60px]" src={camera} />
              </a>
              <a href={resume} download>
                <img className="h-[60px]" src={paper} />
              </a>
            </div>
            <h2 className="mt-6">SAY HI</h2>
            <div className="flex justify-around mx-4 my-2 max-w-[200px]">
              <a href="mailto:larsmontanaro@gmail.com">
                <img className="h-[70px]" src={email} />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/larsmontanaro/"
              >
                <img className="h-[40px] mt-[15px]" src={instagram} />
              </a>
              <a target="_blank" href="https://twitter.com/lars_montanaro">
                <img className="h-[55px] mt-[5px]" src={twitter} />
              </a>
            </div>
          </div>
        </div>
      </Columns>
    </Layout>
  );
};

export const Head = () => <title>Lars Montanaro</title>;

export default TheaterPage;
