import React from "react";

import { Link } from "gatsby";

const Header = () => {
  return (
    <div className="sticky top-0 bg-white p-2 z-10">
      <nav>
        <ul className="flex flex-row justify-between">
          <li className="mx-4">
            <Link to="/theater">theater</Link>
          </li>
          <li className="mx-4">
            <Link to="https://chickenbigcomedy.com">comedy</Link>
          </li>
          {/* <li className="mx-4">
            <Link to="/poetry">poetry</Link>
          </li> */}
          {/* <li className="mx-4">
            <Link to="/gallery">gallery</Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default Header;
